var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d:
        "M3.167 15.042C1.791 13.688 0.5 10.545 0.5 8.479C0.5 3.888 4.28 0.167 8.944 0.167C11.876 0.167 14.896 1.911 16.736 4.345C18.193 1.85 20.922 0.167 24.055 0.167C28.718 0.167 32.499 3.889 32.499 8.479C32.499 10.54 31.205 13.69 29.832 15.041C28.15 16.698 16.5 28.167 16.5 28.167C16.5 28.167 5.071 16.917 3.167 15.042ZM17.066 24.562C17.066 24.562 17.077 24.55 17.079 24.549C17.08 24.55 17.093 24.561 17.093 24.561C17.093 24.561 17.093 24.538 17.093 24.534C17.38 24.237 25.842 15.488 27.166 14.343C28.266 13.392 29.872 10.222 29.872 8.772C29.872 5.54 27.478 2.92 23.744 2.92C21.669 2.92 19.442 4.416 17.871 6.299C18.267 7.22 18.5 8.188 18.5 9.167C18.5 9.459 18.49 9.8 18.475 10.165H17.222C17.222 10.134 17.222 10.094 17.222 10.064C17.222 6.908 13.27 3.416 9.622 3.416C5.975 3.416 3.345 5.355 3.345 8.512C3.345 10.408 5.023 13.645 6.099 14.576C7.563 15.843 16.742 24.242 17.062 24.534C17.063 24.54 17.066 24.562 17.066 24.562Z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }