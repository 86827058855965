var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "models-page" },
    [
      _c(
        "section",
        {
          staticClass: "border-bottom bg-lighter md-up:sticky-top",
          staticStyle: { top: "70px" }
        },
        [
          _c("div", { staticClass: "container py-3" }, [
            _c("nav", { staticClass: "nav sm-down:flex-column" }, [
              _c(
                "span",
                {
                  staticClass:
                    "btn btn-sm btn-light text-left d-block sm-down:w-fill",
                  on: { click: _vm.addArr }
                },
                [
                  _c("i", { staticClass: "i-globe float-left" }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("new_business")))])
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "ml-auto btn btn-sm btn-primary sm-down:w-fill",
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ name: "Businesses_fav" })
                    }
                  }
                },
                [
                  _c(
                    "IconBase",
                    {
                      staticStyle: { "margin-right": "0" },
                      attrs: {
                        height: "13.632",
                        width: "14",
                        viewBox: "0 0 33 28"
                      }
                    },
                    [_c("Favourite")],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-1" }, [
                    _vm._v(_vm._s(_vm.$t("interesting_businesses")))
                  ])
                ],
                1
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("section", [
        _c(
          "div",
          { staticClass: "container py-5" },
          [
            _vm.list.length
              ? _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.list, function(arr, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass:
                          "xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
                      },
                      [_c("business-card", { attrs: { arr: arr } })],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c("mega-loading", {
              attrs: { identifier: _vm.loadingId },
              on: { infinite: _vm.loadPage }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("new-arr", { ref: "new-arr" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }