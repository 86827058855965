<template>
  <main class="models-page">
    <section
      class="border-bottom bg-lighter md-up:sticky-top"
      style="top: 70px;"
    >
      <div class="container py-3">
        <nav class="nav sm-down:flex-column">
          <span
            @click="addArr"
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill"
          >
            <i class="i-globe float-left"></i>
            <span>{{ $t("new_business") }}</span>
          </span>
          <span
            class="ml-auto btn btn-sm btn-primary sm-down:w-fill"
            @click="$router.push({ name: 'Businesses_fav' })"
          >
            <IconBase
              :height="'13.632'"
              :width="'14'"
              :viewBox="'0 0 33 28'"
              style="margin-right: 0"
              ><Favourite
            /></IconBase>
            <span class="ml-1">{{ $t("interesting_businesses") }}</span>
          </span>
        </nav>
      </div>
    </section>

    <section>
      <div class="container py-5">
        <div class="row" v-if="list.length">
          <div
            class="xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
            v-for="(arr, i) in list"
            :key="i"
          >
            <business-card :arr="arr" />
          </div>
        </div>

        <mega-loading
          :identifier="loadingId"
          @infinite="loadPage"
        ></mega-loading>
      </div>
    </section>

    <new-arr ref="new-arr" />
  </main>
</template>

<script>
import NewArr from "./components/new_arr";
import IconBase from "../../../components/icons/IconBase.vue";
import Favourite from "../../../components/icons/set/Favourite.vue";
import BusinessCard from "./components/business-card.vue";

export default {
  name: "Businesses",
  computed: {
    list() {
      return this.$root.list;
    }
  },
  data() {
    return {
      loadingId: +new Date()
    };
  },
  created() {
    if (!this.$root["history"].includes(this.$route.name))
      this.$root["list"] = [];
  },
  mounted() {
    this.$navbar.name = this.$t("businesses");
  },
  methods: {
    addArr() {
      this.$refs["new-arr"].open();
    },

    loadPage($state) {
      this.loaded = false;

      let params = {
        limit: 60,
        offset: this.list.length
      };

      this.$api.v2
        .get("/arps", { params })
        .then(({ data }) => {
          this.length = data.count;
          this.$navbar.name = `${this.$t("businesses")} (${this.length})`;

          if (data["arps"].length) {
            setTimeout(() => {
              if ($state) $state.loaded();
            }, 500);
            this.$root.list.push(...data["arps"]);
          } else $state.complete();
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    }
  },
  components: {
    NewArr,
    IconBase,
    Favourite,
    BusinessCard
  }
};
</script>
